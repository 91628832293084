#navbar {
    background: $colorWhite;
    padding: 0px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    transition: 0.1s ease-in-out;
    &.is-scrolled {
        box-shadow: 0 0px 3px 0 rgb(0, 0, 0, 0.2);
    }
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 80px;
    }
}
.logo {
    a {
        font-weight: $fontBold;
        font-size: $fontSizeSubtitle;
        color: $colorBlack;
        text-decoration: none;
        background: $colorGrey;
        padding: 5px;
    }
}
.nav {
    margin: 0;
    li {
        display: inline-flex;
        margin-right: 30px;
        &:last-child {
            margin-right: 0;
        }
        a {
            display: flex;
            padding: 20px;
            color: $colorBlack;
            text-decoration: underline;
        }
    }
}
