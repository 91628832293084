#clients {
    margin: 150px 0 100px 0;
    .container {
        display: flex;
        align-items: center;
    }
    .item {
        width: calc(100% / 5);
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            text-align: left;
        }
        img {
            transform: scale(0.4);
        }
    }
}
