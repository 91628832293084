html {
    scroll-behavior: smooth;
}
body {
    font-family: "Epilogue", sans-serif;
    font-size: $fontSizeBody;
    color: $colorBlack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.6;
    letter-spacing: 0.01em;
    padding: 80px 0 0 0;
}
::selection {
    background: $colorYellow;
}

h1 {
    font-size: $fontSizeH1;
}
h2 {
    font-size: $fontSizeH2;
}
h3 {
    font-size: $fontSizeH3;
}
h1,
h2,
h3 {
    font-weight: $fontBold;
    letter-spacing: -0.01em;
}
.container {
    max-width: 1200px;
    margin: auto;
}
