#works {
    padding: 160px 0 100px 0;
    background: $colorGrey;
    h2{
        margin-bottom: 60px;
    }
    .container {
        .item-wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            margin: 0 -22px;
            .WorkCard {
                flex: 1 1 33%;
                padding: 0 24px;
                margin-bottom: 60px;
                .img-wrapper{
                    margin-bottom: 24px;
                    overflow: hidden;
                    position: relative;
                    .img{
                        transition: 1s all;
                        width: 100%;
                        height: auto;
                    }
                    .icon-wrapper{
                        background: $colorBlack;
                        display: inline-flex;
                        width: 32px;
                        height: 32px;
                        color: #FFF;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        margin-right: -40px;
                        transition: .2s all;
                    }
                }
                h4{
                    font-weight: $fontBold;
                    margin-bottom: 0px;
                    font-size: $fontSizeSubtitle;
                }
                p{
                    margin: 0;
                    a{
                        color: $colorBlack;
                        font-size: 14px;
                        text-decoration: underline;
                        opacity: .3;
                        transition: .2s all;
                        &:hover{
                            opacity: 1
                        }
                    }
                    .icon{
                        margin-bottom: -4px;
                        margin-left: 2px;
                    }
                }
                .text{
                    font-size: 14px;
                    margin-bottom: 6px;
                    opacity: .5;
                }
                &:hover{
                    .img-wrapper{
                        .img{
                            transform: scale(1.02);
                        }
                        .icon-wrapper{
                            margin-right: 0;
                            margin-bottom: 0;
                        }
                    }
                    
                }
            }
        }
    }
}
