#skills{
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .skill-card{
        padding: 60px 40px;
        text-align: center;
        .img-wrapper{
            margin-bottom: 60px;
        }
        .img{
            width: 126px;
            height: auto;
        }
        h3{
            margin-bottom: 20px;
        }
    }
}