// Colors
$colorBlack : #2D2D2D;
$colorWhite : #FFFFFF;
$colorGreen : #009379;
$colorRed : #FF6250;
$colorPink : #F3AFA8;
$colorGrey : #F3F3F3;
$colorYellow : #F7D684;

// Typography
$fontSizeH1 : 100px;
$fontSizeH2 : 32px;
$fontSizeH3 : 27px;
$fontSizeSubtitle : 22px;
$fontSizeBody: 17px;

$fontRegular: 400;
$fontBold : 600;
