#about{
    padding: 100px 0;
    .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .hero-content{
        .title{
            font-weight: $fontBold;
            font-size: $fontSizeH1;
            letter-spacing: -0.04em;
            margin: 40px 0;
            line-height: 1;
        }
        .subtitle{
            font-size: $fontSizeSubtitle;
            font-weight: $fontBold;
        }
        .text{
            display: block;
            max-width: 530px;
            padding-bottom: 10px;
            line-height: 1.8;
            opacity: .6;
            b{
                font-weight: $fontBold;
            }
        }
    }
    .hero-media{
        max-width: 400px;
        margin-left: 60px;
        user-select: none;
        position: relative;
        &:before{
            content: '';
            display: block;
            width: 125px;
            height: 130px;
            background: $colorGreen;
            position: absolute;
            z-index: 1;
            left: -62px;
            bottom: 64px;
            border-radius: 0 35% 0 35%;
        }
        &:after{
            content: '';
            display: block;
            width: 125px;
            height: 130px;
            background: $colorRed;
            position: absolute;
            z-index: 1;
            right: -16px;
            top: -14px;
            border-radius: 0 100% 0 0;
        }
        .hero-img{
            width: 100%;
            height: auto;
            border-radius: 1000px 1000px 0 0;
        }
    }
}

.social-profiles{
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    a{
        font-weight: $fontBold;
        color: $colorBlack;
        display:inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 0 16px;
        min-width: 52px;
        height: 52px;
        margin: 0 8px 0 0;
        background: rgba(255,255,255,.1);
        opacity: .6;
        transition: .15s ease-in-out;
        &:hover{
            opacity: 1;
        }
    }
    a.text-button{
        color: $colorBlack;
        padding: 0px 40px;
        line-height: 1em;
        margin-right: 16px;
        background-color: $colorYellow;
        border-radius: 100px;
        opacity: 1;
        &:hover{
            background: $colorBlack;
            color:#FFF;
        }
    }
}