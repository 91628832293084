@media only screen and (max-width: 1400px) {
    .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

@media only screen and (max-width: 1200px) {
    
    #about {
        .hero-content{
            .title{
                max-width: 400px;
                font-size: 70px;
            }
        }
        .hero-media{
            max-width: 300px;
        }
    }
}


@media only screen and (max-width: 768px) {
    #navbar{
        border-bottom: 1px solid rgba(0,0,0, .1);
    }
    .logo{
        font-size: 20px;
    }
    .nav{
        li{
            margin: 0;
            a{
                padding: 10px 5px;
                margin: 0 5px;
            }
        }
    }
    #about {
        position: relative;
        padding: 60px 0 80px 0;
        .hero-content{
            .title{
                font-size: 50px;
            }
            .subtitle{
                font-size: 20px;
            }
        }
        .hero-media{
            display: none;
        }
    }
    #works{
        padding: 80px 0 40px 0;
        .item-wrapper{
            display: block !important;
        }
    }
}